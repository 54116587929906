import {
  IGoodsSearchParams,
  ISearchParams,
  TGoodsQnAFormData,
  TGoodsReviewFormData,
} from 'src/@types';
import {
  IGoods,
  IGoodsCategory,
  IGoodsDetails,
  IGoodsInquiry,
  IGoodsReview,
} from 'src/@types/apiResponse';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/common/v1/goods';

/**
 * 상품 목록 조회
 */
export const getGoodsList = async (params?: IGoodsSearchParams) => {
  try {
    const url = `${SUB_URL}`;
    const response = await instance.get<TApiResponseData<IGoods[]>>(url, {
      params,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품 상세 조회
 */
export const getGoodsInfo = async (goodsSid: number, ctegrySid?: number) => {
  try {
    const url = `${SUB_URL}/${goodsSid}`;
    const response = await instance.get<TApiResponseData<IGoodsDetails>>(url, {
      params: {
        ...(ctegrySid && { ctegrySid }),
      },
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품 카테고리 목록 조회
 */
export const getGoodsCategories = async () => {
  try {
    const url = `${SUB_URL}/category`;
    const response = await instance.get<TApiResponseData<IGoodsCategory[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 나의 상품 문의 목록 조회 (Q&A)
 */
export const getMyGoodsQnAList = async () => {
  try {
    const url = `${SUB_URL}/inquiry`;

    const response = await instance.get<TApiResponseData<IGoodsInquiry[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품 상세 - 상품별 문의 목록 조회 (Q&A)
 */
export const getGoodsQnAList = async (goodsSid: number, params: ISearchParams) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/inquiry`;
    const response = await instance.get<TApiResponseData<IGoodsInquiry[]>>(url, {
      params,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품문의 상세 조회 (Q&A)
 */
export const getGoodsQnA = async (goodsSid: number, goodsInquirySid: number) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/inquiry/${goodsInquirySid}`;
    const response = await instance.get<TApiResponseData<IGoodsInquiry>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품 문의 등록 (Q&A)
 */
export const createGoodsQnA = async (goodsSid: number, data: TGoodsQnAFormData) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/inquiry`;

    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'uploadFiles') {
        (value as File[]).forEach((file) => {
          form.append('uploadFiles', file);
        });
      } else {
        form.append(key, String(value));
      }
    });

    const response = await instance.post<TApiResponseData<IGoodsInquiry>>(url, form);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

/**
 * 상품 문의 수정 (Q&A)
 */
export const updateGoodsQnA = async (
  goodsSid: number,
  goodsInquirySid: number,
  data: TGoodsQnAFormData,
) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/inquiry/${goodsInquirySid}`;

    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'uploadFiles') {
        (value as File[]).forEach((file) => {
          form.append('uploadFiles', file);
        });
      } else {
        form.append(key, String(value));
      }
    });

    const response = await instance.put<TApiResponseData<IGoodsInquiry>>(url, form);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '',
    };
  }
};

/**
 * 상품 문의 수정 (Q&A)
 */
export const deleteGoodsQnA = async (goodsSid: number, goodsInquirySid: number) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/inquiry/${goodsInquirySid}`;
    const response = await instance.delete<TApiResponseData<boolean>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품 상세 - 리뷰 목록 조회
 */
export const getGoodsReviews = async (goodsSid: number, params: ISearchParams) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/review`;
    const response = await instance.get<TApiResponseData<IGoodsReview[]>>(url, {
      params,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품 상세 - 포토 리뷰 목록 조회
 */
export const getGoodsPhotoReviews = async (goodsSid: number) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/review/photo`;
    const response = await instance.get<TApiResponseData<IGoodsReview[]>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 내가 작성한 리뷰 목록 조회
 */
export const getMyGoodsReviews = async (params: ISearchParams) => {
  try {
    const url = `/common/v1/review`;
    const response = await instance.get<TApiResponseData<IGoodsReview[]>>(url, {
      params,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품 리뷰 등록
 */
export const createGoodsReview = async (goodsSid: number, orderGoodsNo: string, data: any) => {
  try {
    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'uploadFiles') {
        (value as File[]).forEach((file) => {
          form.append('uploadFiles', file);
        });
      } else {
        form.append(key, String(value));
      }
    });

    const url = `${SUB_URL}/${goodsSid}/orderGoods/${orderGoodsNo}/review`;
    const response = await instance.post<TApiResponseData<IGoodsReview>>(url, form);

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '알 수 없는 오류가 발생했습니다.',
    };
  }
};

/**
 * 상품 리뷰 수정
 */
export const updateGoodsReview = async (
  goodsSid: number,
  orderGoodsNo: string,
  data: TGoodsReviewFormData,
) => {
  try {
    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'uploadFiles') {
        (value as File[]).forEach((file) => {
          form.append('uploadFiles', file);
        });
      } else {
        form.append(key, String(value));
      }
    });

    const url = `${SUB_URL}/${goodsSid}/orderGoods/${orderGoodsNo}/review`;
    const response = await instance.put<TApiResponseData<IGoodsReview>>(url, form);

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '알 수 없는 오류가 발생했습니다.',
    };
  }
};

/**
 * 작성 가능한 리뷰 목록 조회
 */
export const getPossibleReviews = async (params: ISearchParams) => {
  try {
    const url = `/common/v1/review/possible`;
    const response = await instance.get<TApiResponseData<IGoodsReview[]>>(url, {
      params,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        data: response.data.data,
        pagination: response.data.pagination,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 상품 리뷰 이모션 추가
 */
export const addGoodsReviewEmotion = async (
  goodsSid: number,
  orderGoodsNo: string,
  emotionCd: number,
) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/orderGoods/${orderGoodsNo}/review/emotion`;
    const response = await instance.post<TApiResponseData<IGoodsReview>>(url, {
      emotionCd: emotionCd,
    });

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '알 수 없는 오류가 발생했습니다.',
    };
  }
};

/**
 * 상품 리뷰 이모션 삭제
 */
export const deleteGoodsReviewEmotion = async (goodsSid: number, orderGoodsNo: string) => {
  try {
    const url = `${SUB_URL}/${goodsSid}/orderGoods/${orderGoodsNo}/review/emotion`;
    const response = await instance.delete<TApiResponseData<IGoodsReview>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
        data: response.data.data,
      };
    }

    throw new Error(response.data.errorMessage);
  } catch (error) {
    console.error(error);
    return {
      success: false,
      message: error instanceof Error ? error.message : '알 수 없는 오류가 발생했습니다.',
    };
  }
};
