import { t } from 'i18next';
import { TPagination } from 'src/@types';
import { IPayment } from 'src/@types/apiResponse';

export const MOBILE_MAX_WIDTH = 450;
export const MOBILE_FIXED_WIDTH = 450;
export const PAGE_PADDING = 20;
export const HEADER_HEIGHT = 64;
export const FOOTER_HEIGHT = 70;
export const TAB_HEIGHT = 48;
export const BOTTOM_BTN_HEIGHT = 80;
export const MULTI_BOTTOM_BTN_HEIGHT = 148;

export const DEFAULT_PAGE_SIZE = 20;

export const DEFAULT_PAGINATION: TPagination = {
  page: 1,
  size: DEFAULT_PAGE_SIZE,
  offset: 0,
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  first: true,
  last: false,
};

export const COMMON_CODE_NAME = {
  GOODS_ORDER_TYPE_CD: 'GOODS_ORDER_TYPE_CD', // 상품 정렬 타입 코드
  ORDER_STATE_CD: 'ORDER_STATE_CD', // 주문 상태 코드
  INQUIRY_TYPE_CD: 'INQUIRY_TYPE_CD', // 문의 유형 코드
  TEST_STATE_CODE: 'ORDER_STATE_CD', // 문의 유형 코드
  RETURN_DELIVERY_TYPE_CD: 'RETURN_DELIVERY_TYPE_CD', // 반송 배송 타입 코드
};

// 상품 정렬 타입 코드
export const GOODS_ORDER_TYPE_CODE = {
  SELL_COUNT: 230001, // 판매량 많은 순
  LOW_PRICE: 230002, // 낮은 가격 순
  HIGH_PRICE: 230003, // 높은 가격 순
  REVIEW_COUNT: 230004, // 리뷰 많은 순
};

// 약관 사용 유형 코드
export const TERMS_USE_LOC_CODE = {
  SIGN_UP: 820002, // 회원가입
  KIT_RETURN: 820003, // 키트 반송
  PAYMENT: 820004, // 결제
  MENU: 820005, // 메뉴
  AGREEMENT_INSPECTION: 820006, // 검사 동의서
  AGREEMENT_DATA_STORAGE: 820007, // 데이터 보관 및 추가 분석 동의서
};

// 주문 상태 코드
export const ORDER_STATE_CODE = {
  ORDER_COMPLETED: 210101, // 주문완료
  PAYMENT_WAITING: 210102, // 결제대기
  PAYMENT_COMPLETED: 210103, // 결제완료
  DELIVERY_PREPARING: 210104, // 배송 준비중
  DELIVERING: 210105, // 배송중
  DELIVERY_COMPLETED: 210106, // 배송완료
  RETURN_REQUEST: 210107, // 반송요청
  RETURNING: 210108, // 반송중
  RETURN_COMPLETED: 210109, // 반송완료
  ANALYSIS_REQUESTED: 210110, // 분석요청
  ANALYZING: 210111, // 분석중
  ANALYSIS_COMPLETED: 210112, // 분석완료
  ANALYSIS_FAILED: 210113, // 분석실패
  ORDER_CANCEL_COMPLETED: 210114, // 취소완료
  GOODS_PREPARING: 210115, // 상품 준비중
  ANALYSIS_IMPOSSIBLE: 210116, // 분석 불가
  DELIVERY_FAILED: 210117, // 배송실패
};

// 주문 상태 코드 - for labtest
export const TEST_STATE_CODE = {
  ANALYSIS_REQUESTED: 210110, // 분석요청
  ANALYZING: 210111, // 분석중
  ANALYSIS_COMPLETED: 210112, // 분석완료
  ANALYSIS_FAILED: 210113, // 분석실패
};

// 취소 상태 코드
export const CANCEL_STATE_CODE = {
  REQUESTED: 210302, // 반품요청
  AUTO_APPROVED: 210303, // 자동승인
  APPROVED: 210304, // 반품승인
  REJECTED: 210305, // 반품거부
};

// 교환 상태 코드
export const EXCHANGE_STATE_CODE = {
  REQUESTED: 210502, // 교환요청
  APPROVED: 210503, // 교환승인
  REJECTED: 210304, // 교환거부
  COMPLETED: 210505, // 교환완료
};

// 문의 유형 코드
export const INQUIRY_TYPE_CODE = {
  GOODS: 840007, // 상품
};

// 상품 타입 코드
export const GOODS_TYPE_CODE = {
  ANALYSIS: 230101, // 겸사살품
  NORMAL: 230102, // 배송상품
};

// 링크 타입 코드
export const LINK_TYPE_CODE = {
  CURRENT: 901401, // 현재창
  NEW: 901402, // 새창
};

// 이모션 타입 코드
export const EMOTION_TYPE_CODE = {
  LIKE: 400101, // 좋아요
};

export const INIT_PAYMENT: IPayment = {
  totPriceAmt: 0,
  totGoodsAmt: 0,
  totDscntAmt: 0,
  totPaymentAmt: 0,
  totCpnDscntAmt: 0,
  cpnDscntList: [],
  currencyCd: {
    code: 200001,
    value: t('currency'),
    pcode: 2000,
  },
};

// Group Type Code - 공통 코드 그룹 타입 코드
export const GROUP_TYPE_CODE = {
  WITHDRAWAL_REASON: 900501,
  DELIVERY_REQUEST: 900502,
  CANCEL_REASON: 900503,
  EXCHANGE_REASON: 900504,
};

// 배송 요청 코드
export const DELIVERY_REQUEST_CODE = {
  OTHER: 99, // 기타
};

// 쿠폰 할인 타입 코드
export const COUPON_DISCOUNT_TYPE_CODE = {
  PERCENT: 20101, // 할인울
  AMOUNT: 20102, // 할인금액
};

// 회수 배송 타입 코드
export const RETURN_DELIVERY_TYPE_CODE = {
  NONE: 211801, // 해당사항 헚음
  RETURN_REQUEST: 211802, // 회수요청
  DIRECT_DELIVERY: 211803, // 직접배송
};

// 리뷰 작성 시 적립금
export const REVIEW_REWARDS = 1000;
